/* Mobile */
@media only screen and (max-width: 768px) {
    header .hidden-desktop .MuiButton-label {
        border-radius: 50%;
        padding: 0.25em;
        width: unset;
    }
    header .hidden-desktop button > span:first-child {
        padding: 0.25rem;
        background: #ffffffcc;
        border-radius: 50%;
    }
    header .hidden-desktop button > span:first-child > svg {
        color: #000;
    }
}

/* Desktop */
@media only screen and (min-width: 768px) {
    header .header-top li,
    header .header-top a,
    header .nav > li > a,
    header .MuiButton-text .MuiButton-label,
    header #combo-box-demo,
    header #combo-box-demo-label {
        color: #f0cdc9;
    }
    header .header-top .account-menu a {
        color: #000;
    }
    
    header .nav > li > a:focus,
    header .nav > li:hover > a,
    header .MuiSvgIcon-colorSecondary,
    header div.search-icon .MuiIconButton-label {
        color: #d4b375;
    }
    header .MuiInput-underline:after,
    header .MuiInput-underline:before,
    header .MuiInput-underline:hover:not(.Mui-disabled):before {
        border-color: #d4b375;
    }
    
    .nav > li > a,
    header main,
    header #header,
    header div.search-icon,
    #minicart .sub-total,
    #minicart .checkout-button,
    #minicart #minicart-header {
        background-color: #B33630 !important;
    }
    
    .MuiDrawer-root .sub-total > span {
        color: #fff;
    }
    
    .header-top {
        border-bottom: 1px solid #B33630 !important;
    }
}

/* only show one error message at a time (shipping method section) */
#checkoutShipping .column .MuiPaper-elevation0{
    display: none;
}
#checkoutShipping .column .MuiPaper-elevation0:first-child{
    display: flex;
}